<template>
  <Teleport to="body">
    <div
      v-if="showSignIn"
      class="bg-grays-dark/50 fixed inset-0 z-50"
      @click="signinDrawer.setShowSignIn(false)"
    >
      <aside
        class="absolute bottom-0 right-0 flex w-full min-w-full flex-col gap-6 overflow-y-auto rounded-t-md bg-white sm:max-w-sm lg:h-full lg:min-h-screen lg:min-w-0 lg:rounded-none"
        :class="{ 'py-6': step === 'signin', 'pb-6': step === 'welcome' }"
        @click.stop
      >
        <div
          v-if="step === 'signin'"
          class="flex items-center justify-between px-4"
        >
          <h2>Sign in</h2>
          <ZenniIconsIconClose
            size="lg"
            class="text-grays-dark cursor-pointer"
            @click="signinDrawer.setShowSignIn(false)"
          />
        </div>
        <WelcomeOtp
          v-if="step === 'signin'"
          show-in-drawer
          @logged-in="handleGiftId"
        />
        <LandingHouseholdContent
          v-if="step === 'welcome'"
          @close="signinDrawer.setShowSignIn(false)"
        />
      </aside>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { useRouter } from '#imports'
import { useSignInStore } from '~/store/signin'
import { useGuestStore } from '~/store/guest'
const signinDrawer = useSignInStore()
const guestStore = useGuestStore()
const { getSiteConfiguration } = useSiteConfigurationStore()
const { showSignIn, step } = storeToRefs(signinDrawer)
const { primaryUser } = storeToRefs(guestStore)
const router = useRouter()

const handleLogin = async (giftId: string) => {
  await guestStore.loadGift(giftId)
  const storeCode = guestStore.gift?.code?.replaceAll('_', ' ') as string
  await getSiteConfiguration(storeCode)
}

const handleGiftId = async ({ email }: { email: string }) => {
  // load dependents to find the giftId that belongs to this store and this user to be set as the default gift
  await guestStore.loadDependents(email)
  // here we should verify all the business rules to decide how to continue

  const storeGifts = guestStore.dependents?.filter(
    (dependent) => dependent.storeId === guestStore.guest?.storeId,
  )
  // 1. no storeGifts and have dependents, we should switch the store to the dependent
  const gifts = (storeGifts?.length ? storeGifts : guestStore.dependents) ?? []
  let userGift: CampaignGiftGetResponseBody | undefined
  let path
  // find the gift that belongs to this store and this user and the currentCatalog
  userGift = gifts.find(
    (dependent) =>
      dependent.email === email &&
      dependent.policy?.catalogs?.includes(
        guestStore.guest?.store?.currentCatalog || '',
      ),
  )
  // if there are dependents but no gift that belongs to this store/catalog, then
  // select the first dependent gift as default
  // and redirect to home page
  if (!userGift?.id && guestStore.dependents?.length) {
    userGift = guestStore.dependents[0]
    path = '/'
  }
  if (!userGift?.id) {
    // if there are no storeGifts, what should we do?
    router.push('/welcome')
    return
  }
  // filter the gift that belongs to this store and this user
  const indexOfUserAsDependent = guestStore.dependents?.findIndex(
    (dependent) =>
      dependent.id === userGift?.id &&
      dependent.recipientId === userGift?.recipientId &&
      dependent.policyId === userGift?.policyId,
  )
  if (indexOfUserAsDependent !== undefined && indexOfUserAsDependent >= 0) {
    guestStore.dependents?.splice(indexOfUserAsDependent, 1)
  }
  await handleLogin(userGift?.id)
  // ensure the logged in token belongs to the target store
  if (
    primaryUser.value?.applicationId &&
    guestStore.guest?.storeId &&
    primaryUser.value.applicationId !== guestStore.guest.storeId
  ) {
    await guestStore.switchStoreToken(guestStore.guest.storeId)
  }
  router.push({
    path: path ?? router.currentRoute.value.path,
    query: {
      ...router.currentRoute.value.query,
      gift: userGift?.id,
    },
  })
  // if the user is head of household, we should show the welcome onboard flow
  // otherwise, we should close the drawer
  if ((guestStore.dependents?.length || 0) <= 0) {
    signinDrawer.setShowSignIn(false)
  } else {
    signinDrawer.setStep('welcome')
  }
}
</script>
