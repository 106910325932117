<template>
  <template v-if="!onboardStore.ctaSwitchCatalogShown">
    <div
      class="bg-teal-primary absolute right-6 top-9 z-[100] block h-3 w-4 opacity-90"
      style="clip-path: polygon(0% 100%, 50% 0%, 100% 100%)"
    />
    <div
      class="bg-teal-primary absolute right-2 top-12 z-30 flex w-full max-w-xs gap-2 rounded-lg p-4 text-white opacity-90 lg:right-1 lg:max-w-xs"
    >
      <div class="font-semibold leading-6">
        Switch catalogs to browse between eyeglasses and safety glasses
      </div>
      <button
        class="self-start"
        aria-label="Close"
        lang="en"
        @click.stop="handleDismiss()"
      >
        <ZenniIconsIconClose class="text-white" size="base" />
      </button>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useOnboardStore } from '~/store/onboard'
import { useBreakpoints, watchEffect } from '#imports'

const onboardStore = useOnboardStore()
const handleDismiss = () => {
  onboardStore.setSwitchCatalogCtaShown(true)
}
const { isMobile } = useBreakpoints()
const deferClose = () => {
  if (isMobile.value && !onboardStore.ctaSwitchCatalogShown) {
    setTimeout(() => {
      onboardStore.setSwitchCatalogCtaShown(true)
    }, 5000)
  }
}
deferClose()
watchEffect(() => {
  if (isMobile.value && !onboardStore.ctaSwitchCatalogShown) {
    deferClose()
  }
})
</script>
