<template>
  <Teleport to="body">
    <div class="bg-grays-dark/50 fixed inset-0 z-50" @click="emit('close')">
      <aside
        class="absolute bottom-0 right-0 flex w-full min-w-full flex-col gap-6 overflow-y-auto rounded-t-md bg-white px-4 py-6 sm:max-w-sm lg:h-full lg:min-h-screen lg:min-w-0 lg:rounded-none"
        @click.stop
      >
        <div class="flex items-center justify-between">
          <h2>Choose catalog</h2>
          <ZenniIconsIconClose
            size="lg"
            class="text-grays-dark cursor-pointer"
            @click="emit('close')"
          />
        </div>
        <div class="flex flex-col">
          <div
            v-for="catalog in guestCatalogs"
            :key="catalog.code"
            class="flex cursor-pointer justify-between gap-2"
            @click="selected = catalog.code"
          >
            <div
              class="border-grays-light flex flex-1 items-center justify-between gap-2 border-b py-4"
            >
              <div class="flex items-center gap-3">
                <div
                  class="bg-teal-lighter flex h-8 w-8 items-center justify-center rounded-full"
                >
                  <component
                    :is="componentMap[catalog.icon || '']"
                    v-if="componentMap[catalog.icon || '']"
                  />
                </div>
                <span class="font-semibold">{{ catalog.title }}</span>
              </div>
              <div v-if="selected === catalog.code">
                <ZenniIconsIconCheckMarkSolid
                  size="lg"
                  class="stroke-teal-primary fill-teal-primary"
                />
              </div>
            </div>
          </div>
        </div>
        <ZnButton
          type="primary"
          class="w-full text-center"
          @click="handleConfirm"
          >Confirm</ZnButton
        >
        <SwitchCatalogSignInLegend class="text-sm" @close="$emit('close')" />
      </aside>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import {
  onMounted,
  onUnmounted,
  ref,
  resolveComponent,
  useRouter,
} from '#imports'
import { useGuestStore } from '~/store/guest'
import { guestCatalogs } from '~/store/onboard'

const router = useRouter()
const { guest, switchSelectedCatalog, guestStoreUrl } = useGuestStore()
const emit = defineEmits(['close'])
const selected = ref<'vision' | 'safety'>(
  guest.store?.currentCatalog ?? 'vision',
)

const componentMap: {
  [key: string]: string | ReturnType<typeof resolveComponent>
} = {
  ZenniIconsIconEyeglass: resolveComponent('ZenniIconsIconEyeglass'),
  ZenniIconsIconSafety: resolveComponent('ZenniIconsIconSafety'),
}

const handleConfirm = () => {
  if (selected.value === guest.store?.currentCatalog) {
    return emit('close')
  }
  switchSelectedCatalog(selected.value)
  router.push(guestStoreUrl)
  emit('close')
}

onMounted(() => {
  document.body.classList.add('overflow-hidden')
})

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden')
})
</script>
