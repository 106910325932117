<template>
  <div v-if="step === 'explain'" class="p-6">
    <h2 class="text-grays-darkest text-lg font-semibold">How credit works</h2>
    <ProfileExplainCredit class="mt-4 px-6" />
    <ZnButton
      type="primary"
      class="mt-10 w-full text-center"
      @click="step = 'select'"
    >
      Back
    </ZnButton>
  </div>
  <div v-else-if="step === 'select'" class="flex flex-col p-6">
    <img
      src="/img/landing/household.svg"
      alt="An icon of a family"
      width="150"
      class="mx-auto aspect-video object-cover"
    />

    <h2 class="text-grays-darkest mt-4 self-center text-lg font-semibold">
      Who are you getting glasses for today?
    </h2>
    <div
      ref="scrollContainer"
      class="no-scrollbar h-[380px] overflow-y-scroll transition-shadow duration-200 ease-in-out"
      :class="{ 'shadow-below': isScrollable }"
      @scroll="checkScroll"
    >
      <ProfileSelect ref="profileSelectRef" class="mt-8" />
    </div>
    <ZnButton
      type="primary"
      class="mt-10 w-full text-center"
      @click="handleConfirm()"
    >
      Confirm
    </ZnButton>
    <span
      class="text-teal-primary mt-6 cursor-pointer self-center underline underline-offset-2"
      @click="step = 'explain'"
    >
      How does credit work?
    </span>
  </div>
  <div v-show="step === 'welcome'" class="flex flex-col pb-6">
    <div class="h-[300px]">
      <NuxtImg
        class="h-full w-full object-cover"
        src="/img/landing/vision/welcome-vision-modal.jpeg"
        alt="Zenni"
      />
    </div>
    <div
      class="align-center flex flex-col justify-center gap-6 px-6 pt-6 text-center"
    >
      <h4 class="text-lg font-semibold">
        Welcome to Zenni{{ `, ${currentGift?.recipient?.firstName}` }}!
      </h4>
      <span
        >You and your dependent(s) have credit for prescription and
        non-prescription glasses.</span
      >
      <span
        >If you shared a dependent's email with your benefits provider, they'll
        get an invite to redeem glasses, or you can do it for them.</span
      >
    </div>
    <div class="px-6 pt-4">
      <ZnButton
        class="mt-6 w-full text-center"
        data-test="redeem-offer-secondary"
        @click="handleContinue()"
      >
        Continue
      </ZnButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import Select from '../Profile/Select.vue'
import { onBeforeMount, ref, watch, computed } from '#imports'
import { useOnboardStore } from '~/store/onboard'
import { useGuestStore } from '~/store/guest'

const emit = defineEmits(['close'])

const onboardStore = useOnboardStore()
const { setCurrentGift, currentGift, guest, switchStoreToken } = useGuestStore()
const targetGift = computed(() => profileSelectRef.value?.targetGift)

const profileSelectRef = ref<InstanceType<typeof Select>>()
const step = ref<'welcome' | 'select' | 'explain'>()

onBeforeMount(() => {
  step.value = onboardStore.welcomed ? 'select' : 'welcome'
})

const handleConfirm = () => {
  if (profileSelectRef.value) {
    onboardStore.setSessionOnboarded(true)
    if (
      guest.loggedInToken &&
      targetGift.value &&
      targetGift.value.storeId !== currentGift?.storeId
    ) {
      switchStoreToken(targetGift.value.storeId!)
    }
    setCurrentGift(profileSelectRef.value.targetGiftId)
    emit('close')
  }
}

const handleContinue = () => {
  onboardStore.setWelcomed(true)
  step.value = 'select'
}

const scrollContainer = ref(null)
const isScrollable = ref<boolean>(false)

watch(
  () => scrollContainer.value,
  () => {
    if (scrollContainer.value) {
      checkScroll()
    }
  },
)

const checkScroll = () => {
  if (!scrollContainer.value) return

  const { scrollTop, scrollHeight, clientHeight } = scrollContainer.value
  isScrollable.value =
    scrollHeight > clientHeight && scrollTop + clientHeight < scrollHeight
}
</script>

<style scoped>
.shadow-below {
  box-shadow: inset 0px -18px 15px -12px rgba(0, 0, 0, 0.1);
}
</style>
