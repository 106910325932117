<template>
  <ZnModal
    :show="props.showModal"
    data-test="redeem-offer"
    content-class="py-0 !px-0"
    :show-close-icon="false"
    @close="emit('close')"
  >
    <template #body>
      <LandingHouseholdContent @close="$emit('close')" />
    </template>
  </ZnModal>
</template>

<script setup lang="ts">
const emit = defineEmits(['close'])
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
    default: () => false,
  },
})
</script>
