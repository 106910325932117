<template>
  <div class="flex items-center justify-end gap-2">
    <span class="text-grays-dark font-semibold">Choose catalog:</span>
    <div
      class="border-teal-primary flex cursor-pointer gap-3 rounded-[20px] border px-3 py-1"
      @click="showSwitchCatalogDrawer = true"
    >
      <ZenniIconsIconEyeglass v-if="guest.store?.currentCatalog === 'vision'" />
      <ZenniIconsIconSafety v-if="guest.store?.currentCatalog === 'safety'" />
      <span class="font-semibold">{{ currentCatalog }}</span>
    </div>
    <div
      class="cursor-pointer self-start"
      @click="showSwitchCatalogDrawer = true"
    >
      <ZenniIconsIconCaret size="sm" class="-mt-1.5 rotate-180 self-start" />
    </div>
    <SwitchCatalogDrawer
      v-if="showSwitchCatalogDrawer"
      @close="showSwitchCatalogDrawer = false"
    />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref } from '#imports'
import { useGuestStore } from '~/store/guest'

const guestStore = useGuestStore()
const { guest } = storeToRefs(guestStore)
const currentCatalog = computed(() =>
  guest.value.store?.currentCatalog === 'safety'
    ? 'Safety Glasses'
    : 'Eyeglasses',
)
const showSwitchCatalogDrawer = ref(false)
</script>
